import { combineReducers } from "@reduxjs/toolkit";

// 기본 리듀서(material ui 또는 metaport 에서 사용되는 듯)
import { reducer as calendarReducer } from "../slices/calendar";
import { reducer as chatReducer } from "../slices/chat";
import { reducer as formReducer } from "redux-form";
import { reducer as kanbanReducer } from "../slices/kanban";
import { reducer as mailReducer } from "../slices/mail";
import { reducer as notificationReducer } from "../slices/notification";

// 사용자 리듀서(직접 만든 것들)
import global from "./global";
import music from "./music";
import chat from "./chat";
import exhibition from "./exhibition";
import collection from "./collection";
import space from "./space";
import product from "./product";
import client from "../layouts/CloudLayout/clientReducer";

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  // 사용자 리듀서
  global,
  music,
  // 기존 리듀서 때문에 어쩔 수 없이 이름을 chatting으로 사용함.
  chatting: chat,
  exhibition,
  collection,
  space,
  product,
  client,
});

export default rootReducer;
