import React, { useState, useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { SIDE_MENU } from "../../../types/sidemenu";
import { Divider } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles(({ breakpoints }) => ({
  root: ({ isExpanded, isActive }: { isExpanded: boolean; isActive: boolean }) => ({}),
  menuItem: ({ isExpanded, isActive }: { isExpanded: boolean; isActive: boolean }) => ({
    display: "flex",
    padding: isExpanded ? "15px 0" : "15px 5px",
    cursor: "pointer",
    "& *": { color: isActive ? "black" : "#999999" },
  }),
  icon: {
    marginRight: 10,
  },
  divider: {
    margin: "15px 0",
  },
}));
type Props = {
  menu: SIDE_MENU;
  isExpanded: boolean;
};

function MenuItem({ menu, isExpanded }: Props) {
  const [isActive, setActive] = useState(false);
  const classes = useStyles({ isExpanded, isActive });
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (menu.type === "route" && menu.value === location.pathname) setActive(true);
  }, []);
  const onClickHandler = () => {
    if (menu.type === "link") {
      window.open(menu.value, "_blank");
    } else if (menu.type === "route") {
      history.push(menu.value);
      setActive(true);
    }
  };
  if (menu.type === "link" || menu.type === "route")
    return (
      <div className={classes.menuItem} onClick={onClickHandler}>
        <menu.icon className={classes.icon} />
        {isExpanded && <Typography>{menu.title}</Typography>}
      </div>
    );
  else if (menu.type === "divider")
    return (
      <div className={classes.divider}>
        <Divider />
      </div>
    );
  return <></>;
}

export default MenuItem;
