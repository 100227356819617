import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Box, SvgIcon, makeStyles } from "@material-ui/core";
import { Typography } from "@mui/material";
import MenuItem from "./MenuItem";

import {
  AccountBalanceRounded, // 내 전시 관리 아이콘
  ChatOutlined,
  PhotoOutlined,
  OndemandVideoRounded, // 영상 튜토리얼 아이콘
  LocationOnOutlined, // 가상 전시공간 아이콘
  InfoOutlined, // 자주 묻는 질문 아이콘
  HelpOutlineOutlined, // 문의하기 아이콘
} from "@material-ui/icons";

import * as Routes from "../../../routes";
import { SIDE_MENU } from "../../../types/sidemenu";
import shortid from "shortid";

const useStyles = makeStyles(({ breakpoints }) => ({
  root: ({ isExpanded }: { isExpanded: boolean }) => ({
    width: "100%",
  }),
  menuItem: {
    display: "flex",
  },
}));
type Props = {
  isExpanded: boolean;
};

function Menu({ isExpanded }: Props) {
  const { i18n } = useTranslation();
  const SIDE_MENU_ITEMS: Array<SIDE_MENU> = [
    {
      title: i18n.t("내 전시 관리"),
      icon: AccountBalanceRounded,
      type: "route",
      value: Routes.manageExhibition,
    },
    {
      title: i18n.t("내 전시 댓글 관리"),
      icon: ChatOutlined,
      type: "route",
      value: Routes.manageComment,
    },
    //   {
    //     title: "작품관리",
    //     icon: PhotoOutlined,
    //     type: "menu",
    //   },
    {
      type: "divider",
    },
    {
      title: i18n.t("가상전시공간"),
      icon: LocationOnOutlined,
      type: "route",
      value: Routes.spaces,
    },
    {
      title: i18n.t("영상강좌"),
      icon: OndemandVideoRounded,
      type: "route",
      value: Routes.tutorials,
    },
    {
      title: i18n.t("자주묻는질문"),
      icon: InfoOutlined,
      type: "link",
      value: "http://www.onthewall.io/FAQ",
    },
    {
      title: i18n.t("문의하기"),
      icon: HelpOutlineOutlined,
      type: "link",
      value: "http://www.onthewall.io/ask_ask",
    },
  ];
  const classes = useStyles({ isExpanded });

  return (
    <div className={classes.root}>
      {SIDE_MENU_ITEMS.map((menu) => (
        <MenuItem menu={menu} isExpanded={isExpanded} key={shortid.generate()} />
      ))}
    </div>
  );
}

export default Menu;
