import React from "react";
import type { FC, ReactNode } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import * as Routes from "../routes";

interface PhoneGuardProps {
  children?: ReactNode;
}

const PhoneGuardCloud: FC<PhoneGuardProps> = ({ children }) => {
  const { isAuthenticated, isPhone } = useAuth();

  if (isAuthenticated && !isPhone) {
    return <Redirect to={Routes.signUp2 + "?type=cloud"} />;
  }

  return <>{children}</>;
};

PhoneGuardCloud.propTypes = {
  children: PropTypes.node,
};

export default PhoneGuardCloud;
