import React from "react";

import { makeStyles } from "@material-ui/core";
import MenuItem from "./MenuItem";

import shortid from "shortid";
import useCloudMenu from "../../../hooks/useCloudMenu";

const useStyles = makeStyles(({ breakpoints }) => ({
  root: ({ isExpanded }: { isExpanded: boolean }) => ({
    width: "100%",
  }),
  menuItem: {
    display: "flex",
  },
}));
type Props = {
  isExpanded: boolean;
};

function Menu({ isExpanded }: Props) {
  const MENU = useCloudMenu();
  const classes = useStyles({ isExpanded });

  return (
    <div className={classes.root}>
      {MENU.map((menu) => (
        <MenuItem menu={menu} isExpanded={isExpanded} key={shortid.generate()} />
      ))}
    </div>
  );
}

export default Menu;
