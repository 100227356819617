import React from "react";
import type { FC, ReactNode } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import * as Routes from "../routes";
import { useSelector } from "../store";

interface PhoneGuardProps {
  children?: ReactNode;
}

const PhoneGuardCloud: FC<PhoneGuardProps> = ({ children }) => {
  const { isCloudAdmin } = useAuth();
  const { id: clientId } = useSelector((state) => state.client);

  if (!isCloudAdmin) {
    return <Redirect to={`/${clientId}`} />;
  }

  return <>{children}</>;
};

PhoneGuardCloud.propTypes = {
  children: PropTypes.node,
};

export default PhoneGuardCloud;
