import React from "react";
import { db } from "../../lib/firebase";
import moment from "moment";
import "moment/locale/ko";

// types
import type { FC } from "react";

// hooks
import { makeStyles, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

// components
import Page from "../../components/Page";
import EmptyIcon from "../../../images/emptyAlarm.svg";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    width: "100%",
    borderRadius: 10,
    minHeight: 550,
  },
  title: {
    fontWeight: "bold",
  },
  headerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 15px 10px 20px",
    alignItems: "center",
    height: 35,
    backgroundColor: "#fefefe",
    borderRadius: "10px 10px 0 0 ",
  },
  contentContainer: {
    width: "100%",
    [breakpoints.up("md")]: {
      maxHeight: "calc(100vh - 200px)",
      overflowY: "scroll",
      marginBottom: 15,
    },
    [breakpoints.down("sm")]: {
      minHeight: "calc(100vh - 300px)",
    },
  },
  alarmItem: {
    width: "100%",
  },
  alarmContainer: { borderTop: "1px solid #E9EEF4" },
  alarmBox: {
    // display: "flex",
    // justifyContent: "space-between",
    padding: "15px 23px",
    borderBottom: "1px solid #E9EEF4",
    position: "relative",
    "&:hover": {
      cursor: "pointer",
    },
    [breakpoints.down("sm")]: {
      borderRadius: 10,
      margin: "5px 0",
      backgroundColor: "#FAFAFA !important",
    },
  },
  buttons: {
    justifyContent: "flex-end",
    display: "flex",
  },
  deleteAll: { color: "#64748B" },
  readAll: { color: "#64748B" },
  notiTitle: {
    fontSize: 14,
    wordBreak: "break-all",
  },
  textBox: {
    width: "100%",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  },
  notiContent: {
    wordBreak: "break-all",
    color: "#64748B",
    marginBlock: 5,
    fontSize: 14,
  },
  notiTime: { color: "#94A3B8", marginTop: 6, fontSize: 14 },
  point: {
    width: 12,
    height: 12,
    background: "#9C28B0",
    borderRadius: 10,
  },
  pointButton: {
    width: 20,
    height: 36,
    padding: 0,
    margin: 0,
    minWidth: 20,
    maxWidth: 36,
    position: "absolute",
    // top: 0,
    top: "calc(50% - 20px)",
    right: 5,
  },
  emptyContainer: {
    textAlign: "center",
    marginTop: "25%",
    paddingBottom: 240,
  },
  emptyTitle: {
    marginBlock: 15,
  },
  emptyText: {
    color: "#94A3B8",
  },
}));

const NotificationView: FC = () => {
  const classes = useStyles();
  const authContext = useAuth();
  const { i18n } = useTranslation();
  const [notiList, setNotiList] = useState([]);

  const deleteAll = () => {
    const isConfirmed = confirm(i18n.t(`모든 알림을 삭제합니다.`));
    if (isConfirmed) {
      let batch = db.batch();

      db.collection(`User/${authContext.user.id}/Notification`)
        .get()
        .then((notification) => {
          notification.docs.forEach((doc) => {
            const docRef = db
              .collection(`User/${authContext.user.id}/Notification`)
              .doc(doc.id);
            batch.delete(docRef);
          });
          batch.commit().then(() => {
            // ...
          });
        });
    }
  };

  const readAll = () => {
    let batch = db.batch();

    db.collection(`User/${authContext.user.id}/Notification`)
      .get()
      .then((notification) => {
        notification.docs.forEach((doc) => {
          const docRef = db
            .collection(`User/${authContext.user.id}/Notification`)
            .doc(doc.id);
          batch.update(docRef, { isNotificationRead: true });
        });
        batch.commit().then(() => {
          // ...
        });
      });
  };

  const getUserNotifications = () => {
    return db
      .collection(`User/${authContext.user.id}/Notification`)
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        let notifications = [];
        querySnapshot.forEach((doc) => {
          notifications.push(doc.data());
        });
        setNotiList(notifications);
      });
  };

  useEffect(() => {
    getUserNotifications();
  }, []);

  const clickAlarm = (noti) => {
    let userRef = db
      .collection(`User/${authContext.user.id}/Notification`)
      .doc(noti.id);
    userRef
      .update({
        isNotificationRead: true,
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
    window.open(
      `https://vr.leeungno.or.kr/showroom/${noti.exhibitionId}`,
      "_blank"
    );
  };

  const timeToStr = (time) => {
    // return moment(time).format('YYYY.MM.DD HH:mm:ss');
    return moment(time).fromNow();
  };

  const getNotiText = (noti) => {
    switch (noti.type) {
      case "like": // 전시 좋아요
        return i18n.t(`전시 {{exhibitionTitle}}에 좋아요가 눌러졌습니다.`, {
          exhibitionTitle: noti.data.exhibitionTitle,
        });

      case "objectLike": // 작품 좋아요
        return i18n.t(
          `전시 {{exhibitionTitle}}의 작품 {{objectTitle}}에 좋아요가 눌러졌습니다.`,
          {
            exhibitionTitle: noti.data.exhibitionTitle,
            objectTitle: noti.data.objectTitle,
          }
        );

      case "comment": // 전시 댓글
        return i18n.t(
          `전시 {{exhibitionTitle}}에 {{writer}}님이 댓글을 등록하였습니다.`,
          {
            exhibitionTitle: noti.data.exhibitionTitle,
            writer: noti.data.writer,
          }
        );

      case "objectComment": // 작품 댓글
        return i18n.t(
          `전시 {{exhibitionTitle}}의 작품 {{objectTitle}}에 {{writer}}님이 댓글을 등록하였습니다.`,
          {
            exhibitionTitle: noti.data.exhibitionTitle,
            objectTitle: noti.data.objectTitle,
            writer: noti.data.writer,
          }
        );

      case "pay": // 결제
        return i18n.t(`전시 {{exhibitionTitle}}이(가) 결제되었습니다.`, {
          exhibitionTitle: noti.data.exhibitionTitle,
        });

      case "expired": // 기간만료
        return i18n.t(
          `전시 {{exhibitionTitle}}의 기간이 {{date}}일 남았습니다.`,
          {
            exhibitionTitle: noti.data.exhibitionTitle,
            date: noti.data.remainedDate,
          }
        );
    }
  };

  return (
    <Page className={classes.root} title={i18n.t("알림")}>
      <div className={classes.headerContainer}>
        <Typography className={classes.title}>{i18n.t("알림")}</Typography>
        <div className={classes.buttons}>
          <Button
            className={classes.deleteAll}
            onClick={deleteAll}
            variant="text"
          >
            {i18n.t("알림 모두 삭제")}
          </Button>
          <Button className={classes.readAll} onClick={readAll} variant="text">
            {i18n.t("모두 읽음")}
          </Button>
        </div>
      </div>
      <div className={classes.contentContainer}>
        {notiList.length > 0 ? (
          <>
            <div className={classes.alarmContainer}>
              {notiList.map((noti) => (
                <div key={noti.id} className={classes.alarmItem}>
                  <div
                    className={classes.alarmBox}
                    onClick={() => clickAlarm(noti)}
                    style={{
                      backgroundColor: !noti.isNotificationRead
                        ? "#F6F8FC"
                        : "#fff",
                    }}
                  >
                    <div className={classes.textBox}>
                      {console.log(noti.type)}
                      <span className={classes.notiTitle}>
                        {noti.type ? getNotiText(noti) : noti.title}
                      </span>
                      <span className={classes.notiContent}>
                        : {noti.content}
                      </span>
                    </div>
                    <div className={classes.notiTime}>
                      {timeToStr(noti.createdAt.toDate())}
                    </div>
                    {!noti.isNotificationRead ? (
                      <Button className={classes.pointButton}>
                        <div className={classes.point}></div>
                      </Button>
                    ) : (
                      <div className={classes.pointButton}></div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className={classes.emptyContainer}>
            <img src={EmptyIcon} />
            <div className={classes.emptyTitle}>
              {i18n.t("새로운 알림이 없어요!")}
            </div>
            <div className={classes.emptyText}>
              {i18n.t("새로운 소식이 생기면 알려드릴게요")}
            </div>
          </div>
        )}
      </div>
    </Page>
  );
};

export default NotificationView;
