import React from "react";
import * as Routes from "../routes";
import { useTranslation } from "react-i18next";
import {
  AccountBalanceRounded, // 내 전시 관리 아이콘
  ChatOutlined,
  PhotoOutlined,
  OndemandVideoRounded, // 영상 튜토리얼 아이콘
  LocationOnOutlined, // 가상 전시공간 아이콘
  InfoOutlined, // 자주 묻는 질문 아이콘
  HelpOutlineOutlined, // 문의하기 아이콘
  PersonAdd,
  PeopleAlt,
  Publish,
  Dashboard,
  Panorama,
} from "@material-ui/icons";
import { SIDE_MENU } from "../types/sidemenu";
import { useSelector } from "../store";
import useAuth from "./useAuth";

function useCloudMenu() {
  const { i18n } = useTranslation();
  const { id: clientId } = useSelector((state) => state.client);
  const { isCloudAdmin } = useAuth();
  const getMenus = () => {
    if (isCloudAdmin) {
      return [...SIDE_ADMIN_MENU_ITEMS, ...SIDE_MENU_ITEMS, ...SIDE_DEFAULT_MENU_ITEMS];
    } else {
      return [...SIDE_MENU_ITEMS, ...SIDE_DEFAULT_MENU_ITEMS];
    }
  };
  const SIDE_MENU_ITEMS: Array<SIDE_MENU> = [
    {
      title: i18n.t("내 전시 관리"),
      icon: AccountBalanceRounded,
      type: "route",
      value: `/${clientId}${Routes.cloudManageExhibition}`,
    },
    {
      title: i18n.t("내 전시 댓글 관리"),
      icon: ChatOutlined,
      type: "route",
      value: `/${clientId}${Routes.cloudManageComment}`,
    },
  ];
  const SIDE_ADMIN_MENU_ITEMS: Array<SIDE_MENU> = [
    {
      title: i18n.t("관리자"),
      type: "divider",
    },
    {
      title: i18n.t("가입 승인"),
      icon: PersonAdd,
      type: "route",
      value: `/${clientId}${Routes.cloudAdminGrantUser}`,
    },
    {
      title: i18n.t("사용자 관리"),
      icon: PeopleAlt,
      type: "route",
      value: `/${clientId}${Routes.cloudAdminManageUser}`,
    },
    {
      type: "divider",
    },
    {
      title: i18n.t("출판 승인"),
      icon: Publish,
      type: "route",
      value: `/${clientId}${Routes.cloudAdminGrantExhibition}`,
    },
    {
      title: i18n.t("전시 관리"),
      icon: Dashboard,
      type: "route",
      value: `/${clientId}${Routes.cloudAdminManageExhibition}`,
    },
    {
      type: "divider",
    },
    {
      title: i18n.t("홈 배너 관리"),
      icon: Panorama,
      type: "route",
      value: `/${clientId}${Routes.cloudAdminManageBanner}`,
    },
    {
      title: i18n.t("홈 섹션 관리"),
      icon: ChatOutlined,
      type: "route",
      value: `/${clientId}${Routes.cloudAdminSpecialExhibition}`,
    },
    {
      type: "divider",
    },
    {
      title: i18n.t("이벤트 관리"),
      icon: ChatOutlined,
      type: "route",
      value: `/${clientId}${Routes.cloudAdminEvents}`,
    },
    {
      title: i18n.t("사용자"),
      type: "divider",
    },
  ];
  const SIDE_DEFAULT_MENU_ITEMS: Array<SIDE_MENU> = [
    {
      type: "divider",
    },
    {
      title: i18n.t("가상전시공간"),
      icon: LocationOnOutlined,
      type: "route",
      value: `/${clientId}${Routes.cloudSpace}`,
    },
    {
      title: i18n.t("영상강좌"),
      icon: OndemandVideoRounded,
      type: "route",
      value: `/${clientId}${Routes.cloudTutorials}`,
    },
    {
      title: i18n.t("자주묻는질문"),
      icon: InfoOutlined,
      type: "link",
      value: "http://www.onthewall.io/FAQ",
    },
    {
      title: i18n.t("문의하기"),
      icon: HelpOutlineOutlined,
      type: "route",
      value: `/${clientId}${Routes.cloudAsk}`,
    },
  ];
  return getMenus();
}

export default useCloudMenu;
