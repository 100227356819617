import React from "react";
import type { FC } from "react";
import { makeStyles } from "@material-ui/core";

//hooks
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
// images
import InstagramIcon from "../../../images/instagram.svg";
import YoutubeIcon from "../../../images/youtube.svg";
import BlogIcon from "../../../images/blogIcon.svg";

const useStyles = makeStyles(({ breakpoints }) => ({
  footerContainer: {
    borderTop: "1px solid #E9EEF4",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontSize: "0.9em",
    backgroundColor: "white",
  },
  footerBox: {
    maxWidth: 1400,
    width: "100%",
    padding: "30px 100px",
    display: "flex",
    justifyContent: "space-between",
    [breakpoints.down("sm")]: {
      width: "100%",
      padding: "30px",
    },
  },
  footerInfos: {},
  companyName: {
    marginBottom: 20,
    color: "#64748B",
    fontWeight: "bold",
  },
  companyInfo: {
    color: "#64748B",
    lineHeight: 1.5,
  },
  termsBox: {
    marginTop: 20,
    color: "#64748B",
  },
  terms: {
    paddingRight: 30,
    cursor: "pointer",
  },
  footerLinks: {},
  icon: {
    marginLeft: 15,
    cursor: "pointer",
  },
}));

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  return (
    <footer>
      <div className={classes.footerContainer}>
        <div className={classes.footerBox}>
          <div className={classes.footerInfos}>
            <div className={classes.companyName}>{i18n.t("징검다리커뮤니케이션(주)")}</div>
            <div className={classes.companyInfo}>
              {i18n.t("대표 : 김덕은")} | {i18n.t("사업자등록번호 : 488-81-01057")} | {i18n.t("통신판매업번호 : 2019-부산부산진-0928")}
              <br />
              {i18n.t("주소 : 부산광역시 해운대구 센텀중앙로 90 큐비e센텀 1109호")}
              <br />
              {i18n.t("전화번호 : 051 - 715 - 6537")}
              <br />
              {i18n.t("© GDcommunication Co,. Ltd.")}
            </div>
            <div className={classes.termsBox}>
              <span
                className={classes.terms}
                onClick={() => {
                  window.open(
                    "terms/use_condition",
                    "target",
                    "width=500, height=700, left=0, top=0, tollbar=no, menubar=no, location=no, status=no, scrollbars=no"
                  );
                  return false;
                }}
              >
                {i18n.t("이용약관")}
              </span>
              <span
                className={classes.terms}
                style={{ fontWeight: "bold" }}
                onClick={() => {
                  window.open(
                    "terms/pipp",
                    "target",
                    "width=500, height = 700, left=0, top=0, tollbar=no, menubar=no, location=no, status=no, scrollbars=no"
                  );
                  return false;
                }}
              >
                {i18n.t("개인정보처리방침")}
              </span>
            </div>
          </div>
          <div className={classes.footerLinks}>
            <span>
              <img
                className={classes.icon}
                src={InstagramIcon}
                onClick={() => {
                  window.open("https://www.instagram.com/onthewall_official/?hl=ko", "_blank");
                }}
              />
            </span>
            <span>
              <img
                className={classes.icon}
                src={YoutubeIcon}
                onClick={() => {
                  window.open("https://www.youtube.com/channel/UCeItUtEhBJNQGLr9wQQx7AQ", "_blank");
                }}
              />
            </span>
            <span>
              <img
                className={classes.icon}
                src={BlogIcon}
                onClick={() => {
                  window.open("https://blog.naver.com/gdonthewall", "_blank");
                }}
              />
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
