import React, { useEffect, useState } from "react";
import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { makeStyles, Hidden } from "@material-ui/core";
import Header from "./Header";
import { db } from "../../lib/firebase";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "../../store";
import { setClientData } from "./clientReducer";
import SideNavigation from "./SideNavigation";
import Footer from "./Footer";
interface CloudLayoutProps {
  children?: ReactNode;
}

type RouteParams = {
  id: string;
};

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  mainSection: {
    marginTop: 56,
    minHeight: "calc(100vh - 355px)",
    width: "100vw",
    display: "flex",
    [breakpoints.down("sm")]: {
      marginTop: 56,
    },
  },
  main: ({ isExpanded }: { isExpanded: boolean }) => ({
    width: `calc(100vw - ${isExpanded ? 290 : 60}px)`,
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [breakpoints.down("sm")]: {
      width: "100vw",
    },
  }),
  content: {
    backgroundColor: "#FAFAFA",
    width: "100%",
    padding: "0px 30px",
    [breakpoints.down("sm")]: {
      padding: "0 20px",
    },
  },
}));

const CloudLayout: FC<CloudLayoutProps> = ({ children }) => {
  const [isExpanded, setExpand] = useState<boolean>(JSON.parse(sessionStorage.getItem("MAIN_DRAWER")) ?? false);
  const classes = useStyles({ isExpanded });

  return (
    <div className={classes.root}>
      <>
        <Header />
        <section className={classes.mainSection}>
          <Hidden smDown>
            <SideNavigation isExpanded={isExpanded} setExpand={setExpand} />
          </Hidden>
          <div className={classes.main}>
            <div className={classes.content}>{children}</div>
            <Footer />
          </div>
        </section>
      </>
    </div>
  );
};

CloudLayout.propTypes = {
  children: PropTypes.node,
};

export default CloudLayout;
