import React, { useEffect } from "react";
import type { FC } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { create } from "jss";
import rtl from "jss-rtl";
import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";
import { jssPreset, StylesProvider, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import GlobalStyles from "./components/GlobalStyles";
import ScrollReset from "./components/ScrollReset";
import GoogleAnalytics from "./components/GoogleAnalytics";
import { AuthProvider } from "./contexts/FirebaseAuthContext";
import useSettings from "./hooks/useSettings";
import { createTheme } from "./theme";
import routes, { renderRoutes } from "./routes";
import { initializeI18next, i18n } from "./plugins/i18next";
import { I18nextProvider } from "react-i18next";
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
  const { settings } = useSettings();
  useEffect(() => {
    initializeI18next();
  }, []);
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider dense maxSnack={3}>
            {/* <I18nextProvider i18n={i18n}> */}
            <Router history={history}>
              <AuthProvider>
                <GlobalStyles />
                <ScrollReset />
                <GoogleAnalytics />
                {renderRoutes(routes)}
              </AuthProvider>
            </Router>
            {/* </I18nextProvider> */}
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
