import React, { useEffect, useState } from "react";
import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { makeStyles, Hidden } from "@material-ui/core";
import Header from "./Header";
import { db } from "../../lib/firebase";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "../../store";
import { setClientData } from "../../reducers/clientReducer";
import SideNavigation from "./SideNavigation";
import Footer from "./Footer";
interface CloudLayoutProps {
  children?: ReactNode;
}

type RouteParams = {
  id: string;
};

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  mainSection: {
    marginTop: 80,
    minHeight: "calc(100vh - 355px)",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
  },
  main: {
    width: "100vw",
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "0 15px",
  },
}));

const CloudLayout: FC<CloudLayoutProps> = ({ children }) => {
  const [isExpanded, setExpand] = useState<boolean>(JSON.parse(sessionStorage.getItem("MAIN_DRAWER")) ?? false);
  const classes = useStyles({ isExpanded });
  // const dispatch = useDispatch();
  // const [isLoading, setLoading] = useState(true);

  // const { id: clientIdInState } = useSelector((state) => state.client);
  // const { id: clientIdInURL } = useParams<RouteParams>();

  // const location = useLocation();

  // useEffect(() => {
  //   // TODO: 클라우드용 app 을 제작하고, 내부에 스위치를 만들어야함. Main에서는 실행되고, 기획전시에서는 clientId가 따라들어오지 않아
  //   // 이렇게 예외처리를 해뒀으나, 문제가 생길 수 있음. 수정 필요
  //   if (location.pathname.split("/").includes("special")) return setLoading(false);
  //   const clientIdInSessionStorage = sessionStorage.getItem("clientId");

  //   if (!clientIdInState) {
  //     let clientId;
  //     if (clientIdInSessionStorage) {
  //       clientId = clientIdInSessionStorage;
  //     } else {
  //       clientId = clientIdInURL;
  //     }
  //     console.log(clientId);
  //     const clientRef = db.collection("Client").doc(clientId);
  //     clientRef
  //       .get()
  //       .then((doc) => {
  //         if (doc.exists) {
  //           window.sessionStorage.setItem("cloud", clientId); // 세션스토리지 저장
  //           dispatch(setClientData(doc.data())); // 상태 저장
  //         } else {
  //           window.location.href = "/404.html";
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("Error getting document:", error);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   } else {
  //     setLoading(false);
  //   }
  // }, []);

  return (
    <div className={classes.root}>
      {/* {isLoading ? (
        <div>로딩중</div>
      ) : (
        <> */}
      <Header />
      <section className={classes.mainSection}>
        <div className={classes.main}>{children}</div>
        <Footer />
      </section>
      {/* </>
      )} */}
    </div>
  );
};

CloudLayout.propTypes = {
  children: PropTypes.node,
};

export default CloudLayout;
